






























import { Component, Vue } from 'vue-property-decorator';
import firebase from 'firebase/app';
import 'firebase/auth';
import router from '@/router';

@Component({
  components: {
  },
})
export default class Register extends Vue {
    private FormState = true;
    private ModalOpen = false;

    private MailAddress = '';

    private EmailAddressErrorMessage = '';

    Send(): void {
        let state = true;
        this.EmailAddressErrorMessage = '';
        
        var mailAddressRegex = /^[A-Za-z0-9]{1}[+A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/;

        if(this.MailAddress == '') {
            this.EmailAddressErrorMessage = 'メールアドレスを入力してください';
            state = false;
        }

        if(mailAddressRegex.test(this.MailAddress) == false) {
            this.EmailAddressErrorMessage = '正しいメールアドレスを入力してください';
            state = false;
        }

        if(state == true) {
            var auth = firebase.auth();

            auth.sendPasswordResetEmail(this.MailAddress).then(() => {
                this.ModalOpen = true;
            }).catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.error(errorCode);
                console.error(errorMessage);

                if(error.code == 'auth/user-not-found') {
                    this.EmailAddressErrorMessage = '登録されていません'
                }
            });
        }
    }

    Ok(): void {
        router.push({name: 'Login'});
    }
}
